import { lazy } from "react";

const AllBooks = lazy(() => import("../../views/apps/books/AllBooks"));
const FeaturedBooks = lazy(() =>
  import("../../views/apps/books/FeaturedBooks")
);
const AwardWinningBooks = lazy(() =>
  import("../../views/apps/books/AwardWinningBooks")
);
const Activity = lazy(() => import("../../views/apps/Activity"));
const Category = lazy(() => import("../../views/apps/category/Category"));
const CategoryBooks = lazy(() =>
  import("../../views/apps/category/CategoryBooks")
);
const Author = lazy(() => import("../../views/apps/Author"));
const Request = lazy(() => import("../../views/apps/request/Request"));
const News = lazy(() => import("../../views/apps/News"));
const ManageAds = lazy(() => import("../../views/apps/ManageAds"));
const User = lazy(() => import("../../views/apps/User"));
const Seller = lazy(() => import("../../views/apps/Seller"));
const LibraryMember = lazy(() => import("../../views/apps/LibraryMember"));
const Librarian = lazy(() => import("../../views/apps/Librarian"));
const SubscriptionPackage = lazy(() =>
  import("../../views/apps/SubscriptionPackage")
);
const Language = lazy(() => import("../../views/apps/Language"));
const Publisher = lazy(() => import("../../views/apps/Publisher"));

const AppRoutes = [
  {
    element: <Activity />,
    path: "/activity",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <AllBooks />,
    path: "/books/all-books",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <FeaturedBooks />,
    path: "/books/featured-books",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <AwardWinningBooks />,
    path: "/books/award-winning-books",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <Category />,
    path: "/categories",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <CategoryBooks />,
    path: "/categories/:categoryId",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <Author />,
    path: "/authors",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <Request />,
    path: "/requests",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <News />,
    path: "/news",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <ManageAds />,
    path: "/manage-ads",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <User />,
    path: "/users",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <Seller />,
    path: "/sellers",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <Librarian />,
    path: "/librarians",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <LibraryMember />,
    path: "/library-members",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <SubscriptionPackage />,
    path: "/subscription-packages",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <Language />,
    path: "/languages",
    meta: {
      appLayout: true,
    },
  },

  {
    element: <Publisher />,
    path: "/publishers",
    meta: {
      appLayout: true,
    },
  },
];

export default AppRoutes;
